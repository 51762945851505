<template>
  <div class="event-event">
    <img src="/playground_assets/danseuse.png" class="event-icon" alt="Nuance logo">
    <a :href="topNotification.url" class="event-text" target="_blank">
      <span>
        {{ topNotification.first_part }}
      </span>
      <span class="event-text2">{{ topNotification.bold_part }}</span>
      <span>{{ topNotification.last_part }}</span>
    </span>
    </a>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Event',
  data() {
    return {
      airtableApiKey: "pat099JSzRaXCiaZA.c1f52c67a3c8c15dacb4f6418558a57a883b39f266845c063f3d3c27eeabbe4f",
      topNotification: {first_part: null, bold_part: null, last_part: null, url: "https://nuance.radio"}
    }
  },
  methods: {
     fetchTopNotification() {
      axios.get('https://api.airtable.com/v0/appsaXjixp0CrLeHP/top_notifications?maxRecords=1&view=Grid%20view', {headers: {'Authorization': `Bearer ${this.airtableApiKey}`}})
      .then(response => {
        this.topNotification = response.data.records[0].fields;
      })
    }
  },
  beforeMount () {
    this.fetchTopNotification();
  },
}
</script>

<style scoped>
.event-event {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  top: HalfUnit;
  left: 0px;
  bottom: HalfUnit;
  position: absolute;
}

.event-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.event-text {
  color: #000000;
  font-size: var(--event);
  font-style: normal;
  font-family: Syne;
  font-weight: 400;
  text-decoration: underline   none;
}
.event-text2 {
  font-weight: 700;
  text-decoration: underline;
}

@media(max-width: 1200px) {
  .event-icon {

    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .event-text {
    font-size: 0.9vw;
  }
}
@media(max-width: 991px) {
  .event-icon {

    margin-left: 0.8vw;
  }
  .event-text {
    font-size: 1.2vw;
  }
}
@media(max-width: 767px) {
  .event-event {
    display: none;
  }
}
</style>
