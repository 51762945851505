<template>
  <div class="home-container">
    <app-navbar
      @close-about="closeAbout"
      @open-about="openAbout"
      :showAbout="showAbout"
    ></app-navbar>
    <app-main @close-about="closeAbout" :showAbout="showAbout"></app-main>
    <app-footer
      @close-about="closeAbout"
      @open-about="openAbout"
      :showAbout="showAbout"
    ></app-footer>
  </div>
</template>

<script>
import AppNavbar from "../components/navbar";
import AppMain from "../components/main";
import AppFooter from "../components/footer";

export default {
  name: "App",
  components: {
    AppMain,
  },
  metaInfo: {
    title: "Nuance Radio",
    meta: [
      {
        property: "og:title",
        content: "Nuance Radio",
      },
    ],
  },
  data() {
    return {
      showAbout: false,
    };
  },
  methods: {
    openAbout() {
      this.showAbout = true;
    },
    closeAbout() {
      this.showAbout = false;
    },
  },
};
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
</style>
