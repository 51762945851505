<template>
  <div class="main-main" @click="closeAbout">
    <div
      class="static-bg"
      :class="{ movingBg: audioPlaying }"
      :style="backgroundStyle"
    ></div>
    <div class="main-hero section-container">
      <div class="about-box" v-show="showAbout">
        <about></about>
      </div>
      <div class="main-max-width max-width" v-show="!showAbout">
        <div class="main-last-played">
          <div class="header-pasttrack">
            <span class="main-track">LAST PLAYED</span>
          </div>
          <last-played :lastTracks="lastTracks"></last-played>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LastPlayed from "./last-played";
import About from "./about";
export default {
  name: "MainLastPlayed",
  props: {
    showAbout: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LastPlayed,
    About,
  },

  data() {
    return {
      airtableApiKey: "pat099JSzRaXCiaZA.c1f52c67a3c8c15dacb4f6418558a57a883b39f266845c063f3d3c27eeabbe4f",
      currentTrack: null,
      lastTracks: [],
      backgrounds: [],
      isLandscape: true,
      audioPlaying: false,
      currentBackgroundUrl:
        "/playground_assets/nuance-background-test-4-1500h.jpg",
    };
  },
  methods: {
    fetchNuanceTracks() {
      axios.get("https://stream.nuance.radio/track_list").then((response) => {
        this.currentTrack = response.data.shift().track;
        this.lastTracks = response.data.slice(0, 6).map((e) => e.track);
      });
    },
    fetchBackgrounds() {
      axios
        .get(
          "https://api.airtable.com/v0/appsaXjixp0CrLeHP/backgrounds?maxRecords=24&view=Grid%20view",
          { headers: { Authorization: `Bearer ${this.airtableApiKey}` } }
        )
        .then((response) => {
          this.backgrounds = response.data.records.map(
            (r) => r.fields.background_url
          );
        });
    },
    pickBackground() {
      this.currentBackgroundUrl = this.backgrounds[new Date().getHours()];
    },
    handleResize() {
      this.isLandscape = window.innerWidth > window.innerHeight;
    },
    closeAbout() {
      if (this.showAbout) {
        this.$emit("close-about", false);
      }
    },
    changeAudioPlayingState(isPlaying) {
      this.audioPlaying = isPlaying;
    },
  },
  computed: {
    backgroundStyle() {
      const currentHour = new Date().getHours();
      const bg_index = currentHour < 10 ? "0" + currentHour : currentHour;
      const orientation = this.isLandscape ? "horizontal" : "vertical";
      const url = `https://dml0aitrk7z7q.cloudfront.net/backgrounds/${orientation}/nuance-background-${bg_index}h.jpg`;
      return `background-image: url("${url}"),url("/playground_assets/default_background.jpeg"),linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgb(0, 0, 0) 100.00%)`;
    },
  },
  beforeMount() {
    this.fetchBackgrounds();
  },
  mounted() {
    this.handleResize();
    this.fetchNuanceTracks();
    setInterval(this.fetchNuanceTracks, 5000);
    setInterval(this.pickBackground, 1000);
    window.addEventListener("orientationchange", this.handleResize);
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.about-box {
  max-width: 100vh;
  margin: 10px;
  height: 100%;
}

.main-main {
  width: 100%;
  /* display: flex; */
  flex: 1;
  position: relative;
  border-color: #000000;
  border-width: 0.5rem;
}

.static-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  z-index: -1;
}

@media (min-width: 991px) {
  .main-main {
    overflow: hidden;
  }

  .movingBg {
    animation: psyche 55s infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  @keyframes psyche {
    0% {
      transform: scale(1) skew(0deg);
    }
    100% {
      transform: scale(1.5) skew(20deg);
    }
  }
}

.main-hero {
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: center;
  background-color: transparent;
}
.section-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.main-max-width {
  width: 40%;
  align-items: center;
  flex-direction: row;
  /* justify-content: center; */
}
.main-last-played {
  width: 100%;
  position: relative;
}
.header-pasttrack {
  top: 4px;
  left: 0px;
  position: absolute;
  transform: translateY(-100%);
  flex-direction: column;
  justify-content: flex-start;
}
.main-track {
  color: #ff7676;
  font-size: var(--played) !important;
  transform: translateY(0.2%);
  font-style: normal;
  font-family: Syne;
  line-height: 1;
  padding: 2px 4px 2px 4px;
  background-color: #ffffff;
}
.main-event {
  display: none;
  align-items: center;
}
.main-text {
  color: #000000;
  font-style: normal;
  font-family: Syne;
  font-weight: 400;
  text-decoration: underline none;
}
.main-text2 {
  font-weight: 700;
}
.main-socials {
  display: none;
}
.main-root-class-name {
  height: 100%;
}
@media (max-width: 1600px) {
  .main-max-width {
    width: 45%;
  }

  .main-root-class-name {
    height: 100%;
  }
}
@media (max-width: 1200px) {
  .main-main {
    border-width: 0.8vw;
  }
  .main-hero {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .main-max-width {
    width: 60%;
  }
  .main-last-played {
    width: 100%;
  }
  .main-track {
    transform: translateY(20%);
    line-height: 1;
    padding: 0.2vw 6px;
  }
  .main-text {
    font-size: 0.9vw;
  }
  .main-root-class-name {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .main-main {
    border-width: 0.8vw;
  }
  .main-hero {
    padding-top: 40px;
    padding-left: 100;
    padding-right: 100;
    justify-content: unset;
  }
  .main-max-width {
    width: 80%;
    height: auto;
    flex-direction: column;
  }
  .main-last-played {
    margin-top: 60px;
    padding-bottom: 15vw;
  }
}
@media (max-width: 767px) {
  .main-hero {
    width: 100%;
    align-self: flex-start;
    padding: 40px 0px 0px 0px;
  }

  .main-max-width {
    widows: 100%;
  }
  .main-event {
    display: flex;
    background: white;
    padding-top: 1vw;
    padding-left: 2vw;
    margin-bottom: 3vw;
    padding-right: 2vw;
    padding-bottom: 1vw;
  }
  .main-text {
    font-size: 3vw;
  }
  .main-socials {
    display: flex;
    background: white;
    padding-top: 1vw;
    padding-left: 1vw;
    margin-bottom: 10vw;
    padding-right: 1vw;
    padding-bottom: 1vw;
  }
  .main-icon {
    width: 4vw;
    margin-right: 1.3vw;
  }
  .main-icon2 {
    width: 3.5vw;
    margin-right: 1.5vw;
  }
  .main-icon4 {
    width: 4vw;
  }
  .header-pasttrack {
    top: 4px;
    left: 0px;
    position: absolute;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .main-hero {
    padding-left: 0;
    padding-right: 0px;
  }
  .main-track {
    transform: translateY(1, 1);
    padding: 2px 4px 2px 4px;
  }
  .main-socials {
    background: white;
  }
}
</style>
