import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import App from './views/app'
import Playing from './views/playing'
import LastPlayed from './views/lastplayed'
import './style.css'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'App',
      path: '/app',
      component: App
    },
    {
      name: 'Playing',
      path: '/app/playing',
      component: Playing
    },
    {
      name: 'LastPlayed',
      path: '/app/lastplayed',
      component: LastPlayed
    }
  ],
})
