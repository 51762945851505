<template>
  <div class="last-played-last-played grid-pasttrack">
    <last-track
      v-for="track in lastTracks"
      :key="track.name"
      :artist="track.artist"
      :title="track.title"
      :album="track.album"
      :year="track.year"
      :discogsUrl="track.discogs_url"
      :spotifyUri="track.spotify_uri"
      :imageUrl="track.image_url"
    >
    </last-track>
  </div>
</template>

<script>
import LastTrack from "./last-track";

export default {
  name: "LastPlayed",
  props: {
    lastTracks: {
      type: Array,
      default: [],
    },
  },
  components: {
    LastTrack,
  },
};
</script>

<style scoped>
.last-played-last-played {
  position: relative;
}
.grid-pasttrack {
  width: 100%;
  display: grid;
  padding: 4px;
  align-self: center;
  grid-row-gap: 4px;
  grid-column-gap: 4px;
  justify-content: center;
  background-color: #ffffff;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 991px) {
  .grid-pasttrack {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
