<template>
  <div class="home-container">
    <main-last-played @close-about="closeAbout" :showAbout="showAbout"></main-last-played>
  </div>
</template>

<script>
import MainLastPlayed from '../components/main-last-played.vue';

export default {
  name: "App",
  components: {
    MainLastPlayed,
  },
  metaInfo: {
    title: "Nuance Radio",
    meta: [
      {
        property: "og:title",
        content: "Nuance Radio",
      },
    ],
  },
  data() {
    return {
      showAbout: false,
    };
  },
  methods: {
    openAbout() {
      this.showAbout = true;
    },
    closeAbout() {
      this.showAbout = false;
    },
  },
};
</script>

<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
</style>
