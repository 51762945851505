<template>
  <div class="icons">
    <a v-if="discogsUrl !== null" :href="discogsUrl" target="_blank">
        <img alt="Discogs link" class="icon" width="100%" height="100%" src="/playground_assets/discogs.svg">
    </a>
    <a v-if="spotifyUri !== null" :href="spotifyUri" target="_blank">
        <img alt="Spotify link" class="icon" width="100%" height="100%" src="/playground_assets/spotify.svg">
    </a>
        <a v-if="webShareApiSupported" @click="shareViaWebShare">
    <img alt="Share link" class="icon" width="100%" height="100%" src="/playground_assets/share.svg">
    </a>
  </div>
</template>

<script>

export default {
  name: 'TrackIcons',
  props: {
    spotifyUri: {
      type: String,
      default: null
    },
    discogsUrl: {
        type: String,
        default: null
    },
    trackTitle: {
        type: String,
        default: null
    },
    trackArtist: {
        type: String,
        default: null
    }
  },
  methods: {
    shareViaWebShare() {
      navigator.share({
        title: 'Nuance Radio',
        text: `I discovered ${this.trackTitle} by ${this.trackArtist} on Nuance Radio.`,
        url: 'https://nuance.radio'
      })
    }
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    }
  }
}
</script>

<style scoped>
.icons {
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--dl-space-space-unit);
  width: 100%;
}

.icon {
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  flex-direction: column;
  margin-right: 5px;
  margin-left: 5px;
}


</style>
