<template>
  <div class="footer">
    <div class="about" @click="toggleAbout">
      <span v-if=!showAbout>about</span>
      <span v-else>back to live</span>
    </div>
    <div class="container invert">
      <socials></socials>
    </div>
  </div>
</template>

<script>
import Socials from './socials.vue'
import AppEvent from "./event.vue";
export default {
  name: "AppFooter",
  components: {
    Socials,
    AppEvent
  },
  props: {
    button1: {
      type: String,
      default: "log in",
    },
    logo_path: {
      type: String,
      default: '/playground_assets/logo.png',
    },
    showAbout: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleAbout () {
      if (!this.showAbout) {
        this.$emit("open-about");
      } else {
        this.$emit("close-about")
      }
    }
  }
};
</script>

<style scoped>
.footer {
  display: none;
}
.about {
  font-family: Syne;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color:#fff;
  padding-top: 20px;
}
.container {
  margin-top: 1.5rem;
  flex-direction: row;
}
.invert {
  filter: invert(100);
}
@media(max-width: 768px) {
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 40px;
    justify-content: center;
    background-color: #000;
  }
}
</style>
