<template>
  <div class="socials">
    <a
      href="https://www.facebook.com/nuance.radio"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img class="social-icon" src="playground_assets/facebook.svg" alt="Facebook">
    </a>
    <a
      href="https://www.instagram.com/nuance.radio/"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img class="social-icon" src="playground_assets/instagram.svg" alt="Instagram">
    </a>
    <a
      href="https://twitter.com/nuance_radio"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img class="social-icon" src="playground_assets/twitter.svg" alt="Twitter">
    </a>
    <a
      href="https://www.youtube.com/channel/UCFrdIBkAj018EZaagH0MMng"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img class="social-icon" src="playground_assets/youtube.svg" alt="YouTube">
    </a>
    <a
      href="mailto:contact@nuance.radio"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img class="social-icon" src="playground_assets/mail.svg" alt="Send email">
    </a>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.socials {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.social-icon {
  height: 1rem;
  width: 1rem;
  text-decoration: none;
  flex-direction: column;
  display: flex;
  margin-left: .5rem;
  margin-right: .5rem;
}
</style>
