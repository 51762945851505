<template>
  <div class="now-playing-now-playing">
    <div class="now-playing-header">
      <span class="now-playing-track">NOW PLAYING</span>
    </div>
    <div class="now-playing-playing-track">
      <div class="now-playing-artwork">
        <div class="now-playing-image-container" :style=coverBackground>
          <div class="cover-button" :class="{ 'play-control': !isPlaying, 'pause-control': isPlaying }" @click="controlPlayer()"></div>
          <span class="hidden">
                <video v-if="hlsIsSupported" :poster="imageUrl" :title="videoTitle" ref="radioPlayer" playsinline></video>
                <audio v-else ref="radioPlayer" :title="videoTitle"></audio>
            </span>
        </div>
      </div>
      <div class="now-playing-info">
        <div class="now-playing-text">
          <h1 class="now-playing-artist">{{ artist | truncate(25, "...") }}</h1>
          <span class="now-playing-title">{{ title }}</span>
          <span class="now-playing-album"><span v-if="year !== null">{{year}} - </span>{{ album }}</span>
        </div>
        <track-icons :spotifyUri="spotifyUri" :discogsUrl="discogsUrl" :trackTitle="title" :trackArtist="artist"></track-icons>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import TrackIcons from './track-icons.vue';

export default {
  name: 'NowPlaying',
  components: {TrackIcons},
  props: {
    artist: {
      type: String,
      default: 'ARTIST',
    },
    title: {
      type: String,
      default: 'TITLE',
    },
    album: {
      type: String,
      default: 'ALBUM',
    },
    year: {
      type: String,
      default: null,
    },
    discogsUrl: {
      type: String,
      default: null,
    },
    spotifyUri: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    }
  },
  data () {
    return {
      isPlaying: false,
      streamUrl: "https://stream.nuance.radio/live.m3u8",
      renderTime: new Date(),
      minButtonOpacity: .1,
      buttonOpacity: .7,
      buttonOpacityStyle: "opacity: .7;"
    };
  },
  methods: {
    controlPlayer() {
      let player = this.$refs["radioPlayer"];
      let hls = new Hls();
      if (this.isPlaying !== true) {
        if (this.hlsIsSupported) {
          hls.loadSource(this.streamUrl);
          hls.attachMedia(player);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            player.play();
          });
        } else {
          player.src = this.streamUrl;
          player.play();
        }
      } else {
        player.src = "";
        hls.stopLoad();
        hls.destroy();
      }
      this.isPlaying = !this.isPlaying;
      this.$emit("audioPlaying", this.isPlaying)
    },
    computeButtonOpacity() {
      if (this.isPlaying) {
        let now = new Date();
        let delta = (now.getTime() - this.renderTime.getTime()) / 1000000;
        this.buttonOpacity -= delta;
        if (this.buttonOpacity >= 0){
          this.buttonOpacityStyle = `opacity: ${this.buttonOpacity};`;
        };
      } else {
        this.buttonOpacityStyle = "opacity: .7;"
      }
    },
    setMediaSession() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
        title: "Nuance Radio",
        artist: `${this.artist} - ${this.title}`,
        album: this.album,
        artwork: [
            { src: "https://nuance.radio/favicon/android-icon-192x192.png", sizes: '192x192', type: 'image/png' },
        ]
        });
    }
    }
  },
  computed: {
    coverBackground() {
      return `background-image:url('${this.imageUrl}'),url('/playground_assets/keep_digging.png')`;
    },
    hlsIsSupported() {
      return Hls.isSupported()
    },
    videoTitle() {
      return `Nuance Radio: ${this.artist} - ${this.title} `
    }
  },
  watch: {
    // whenever track title changes, this function will run
    title(newTitle, oltTitle) {
      this.setMediaSession();
    }
  },
  mounted() {
    setInterval(this.computeButtonOpacity, 1000)
    this.setMediaSession();
  }
}
</script>

<style scoped>
.now-playing-now-playing {
  width: 100%;
  height: 100%;
  position: relative;
  margin-right: var(--dl-space-space-fourunits);
}
.now-playing-header {
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
}
.now-playing-track {
  color: #ff0000;
  font-size: var(--played) !important;
  align-self: center;
  font-style: normal;
  font-family: Syne;
  line-height: 1;
  padding: 4px 4px 2px 4px;
  background-color: #ffffff;
}
.now-playing-playing-track {
  width: 100%;
  display: flex;
  align-self: center;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.now-playing-artwork {
  width: 100%;
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: flex-start;
}
.now-playing-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
}
.now-playing-image {
  width: 70%;
  cursor: pointer;
  object-fit: cover;
  text-decoration: none;
}
.now-playing-image1 {
  width: 70%;
  cursor: pointer;
  display: none;
  object-fit: cover;
}
.now-playing-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.now-playing-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.now-playing-artist {
  color: #000000;
  font-size: var(--artist) !important;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Syne;
  font-weight: 600;
  line-height: 1vw;
}
.now-playing-title {
  color: #000000;
  font-size: var(--title) !important;
  align-self: center;
  font-family: Syne;
  line-height: 2vw;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.now-playing-album {
  color: #000000;
  font-size: var(--album) !important;
  align-self: center;
  font-style: italic;
  font-family: Syne;
  font-weight: 300;
  line-height: 0.8vw;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
}
.now-playing-icons {
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--dl-space-space-unit);
}

.now-playing-icon {
  padding-left: 10px;
  display: block;
  max-width: 30px;
  max-height: 30px;
  width: 2.25vmax;
  height: 2.25vmax;
}

/* .now-playing-discogslink {
  left: 0px;
  right: 0px;
  width: 25px;
  bottom: -5px;
  height: 30px;
  margin: auto;
  position: absolute;
} */
@media(max-width: 1600px) {

  .now-playing-artist {
    line-height: 2vw;
  }
  .now-playing-title {
    line-height: 2vw;
  }
  .now-playing-album {
    line-height: 1vw;
  }

  .now-playing-discogslink {
    top: -7px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
  }
}
@media(max-width: 1200px) {
  .now-playing-now-playing {
    width: 100%;
    height: 100%;
  }
  .now-playing-image-container {
    background-image: url('https://i.discogs.com/G7Y1ld1eBjQZ6_NjwpWbBVWyVWJYnLdC3Lnb8jdeLt4/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwNDg5/MTc4LTE0OTg0OTY1/MDctODY4My5qcGVn.jpeg');
  }
  .now-playing-title {
    line-height: 3vw;
  }
  .now-playing-album {
    line-height: 1.5vw;
  }

}
@media(max-width: 991px) {
  .now-playing-now-playing {
    margin-left: 0px;
    margin-right: 0px;
  }
  .now-playing-header {
    left: 0px;
    right: 0px;
    margin: auto;
  }
  .now-playing-playing-track {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .now-playing-artist {
    color: #000000;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    font-family: Syne;
    line-height: 4vw;
  }
  .now-playing-title {
    color: #000000;
    line-height: 5vw;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .now-playing-album {
    color: #000000;
    line-height: 2vw;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }

  .now-playing-icon {
    padding-left: 10px;
    display: block;
    width: 25px;
    height: 25px;
  }
}
@media(max-width: 767px) {
  .now-playing-playing-track {
    width: 100%;
    margin-right: 0px;
  }

}
@media(max-width: 479px) {
  .now-playing-track {
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .now-playing-artist {
    margin-top: 5px;
    line-height: 4.5vw;
  }

  .now-playing-title {
    line-height: 6vw;
  }

  .now-playing-album {
    line-height: 2.5vw;
  }
}


.hidden {
  display: none;
}

.cover-button {
    padding: 10%;
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: auto;
    object-fit: cover;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1000;
    background-size: cover;
    opacity: .5;
    cursor: pointer;
}

.cover-button:hover {
  opacity: 1;
}

.play-control{
  background-image: url("/playground_assets/play.png");
  transition: opacity 0.5s;
}
.pause-control {
  background-image: url("/playground_assets/pause.png");
  opacity: 0;
  transition: opacity 5s;  
}

.pause-control:hover {
  background-image: url("/playground_assets/pause.png");
  opacity: 1;
  transition: opacity 0.5s;  
}
</style>
