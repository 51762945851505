<template>
  <div class="navbar-container">
    <app-event></app-event>
    <div class="navbar-logo">
      <img alt="Nuance logo" :src="logo_path" class="navbar-image" @click="toggleAbout" />
    </div>
    <div class="navbar-about" @click="toggleAbout">
      <span v-if=!showAbout>about</span>
      <span v-else>back to live</span>
    </div>
  </div>
</template>

<script>
import AppEvent from "./event";

export default {
  name: "Navbar",
  props: {
    button1: {
      type: String,
      default: "log in",
    },
    logo_path: {
      type: String,
      default: '/playground_assets/logo.png',
    },
    showAbout: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppEvent,
  },
  methods: {
    toggleAbout () {
      if (!this.showAbout) {
        this.$emit("open-about");
      } else {
        this.$emit("close-about")
      }
    }
  }
};
</script>

<style scoped>
.navbar-container {
  top: 1;
  width: 100%;
  display: flex;
  z-index: 10000;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  position: relative;
  background-color: #fff;
}

.sticky {
  position: fixed;
}


.navbar-logo {
  width: 120px;
  position: absolute;
  cursor: pointer;
}
.navbar-image {
  width: 100%;
  height: 100%;
}
.navbar-about {
  flex: 0 0 auto;
  right: 10px;
  position: absolute;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: row;
  text-decoration: underline;
  font-family: Syne;
  font-size: .75rem;
  cursor: pointer;
}
.navbar-icon {
  height: 1.1rem;
  margin-right: 0.4rem;
  text-decoration: none;
}
.navbar-icon02 {
  height: 0.95rem;
  margin-right: 0.6rem;
  margin-bottom: 0.05rem;
  text-decoration: none;
}
.navbar-icon04 {
  height: 1.1rem;
  text-decoration: none;
}
.navbar-max-width {
  position: relative;
}
.navbar-chat {
  flex: 0 0 auto;
  display: none;
  align-items: center;
  flex-direction: row;
}
.navbar-icon06 {
  width: 24px;
  height: 24px;
  margin-left: 2px;
  margin-right: var(--dl-space-space-halfunit);
}
.navbar-text {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-family: Syne;
  font-weight: 400;
  line-height: 1.5;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: underline none;
}
.navbar-login {
  flex: 0 0 auto;
  display: none;
  align-items: center;
  flex-direction: row;
}
.navbar-button {
  color: #ffffff;
  font-family: Syne;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: #000000;
}
.navbar-button1 {
  color: #000000;
  font-size: 14px;
  font-family: Syne;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #000000;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.navbar-burger-menu {
  display: none;
  fill: #105749;
  width: 24px;
  height: 24px;
}
@media (max-width: 1600px) {
  .navbar-icon {
    height: 1.1rem;
  }
  .navbar-icon02 {
    height: 0.95rem;
  }
  .navbar-icon04 {
    height: 1.1rem;
  }
}
@media (max-width: 1200px) {
  .navbar-navbar {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
  }
  .navbar-about {
    margin-right: 0.4vw;
  }
  .navbar-icon {
    height: 1rem;
    margin-right: 0.4rem;
  }
  .navbar-icon02 {
    height: 0.9rem;
    margin-right: 0.5rem;
  }
  .navbar-icon04 {
    height: 1rem;
  }
}
@media (max-width: 991px) {
  .navbar-navbar {
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
  .navbar-logo {
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
    justify-content: space-between;
  }
  .navbar-about {
    margin-right: 0.8vw;
  }
  .navbar-icon {
    height: 0.9rem;
  }
  .navbar-icon02 {
    height: 0.8rem;
    margin-right: 0.4rem;
  }
  .navbar-icon04 {
    height: 0.9rem;
  }
  .navbar-max-width {
    position: relative;
  }
}
@media(max-width: 767px) {

  .navbar-about {
    display: none;
  }
  .navbar-max-width {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .navbar-chat {
    display: none;
  }
  .navbar-login {
    display: none;
  }
  .navbar-burger-menu {
    display: none;
  }
}

@media(max-width: 767px) {
  .event-container {
    display: none;
  }
}
</style>
