<template>
  <div class="container cover">
    <div
      @mouseover="hoverCover = true"
      @mouseleave="hoverCover = false"
      class="image-container hover-background"
      :style="coverBackground"
    >
      <div class="hover-track">
        <div class="info">
          <h1 class="hover-text-artist">{{ artist | truncate(16, '...')  }}</h1>
          <span class="hover-text-track">
            <span>{{ title | truncate(25, '...') }}</span>
            <br />
            <span></span>
          </span>
        </div>
        <track-icons :discogsUrl="discogsUrl" :spotifyUri="spotifyUri" :trackArtist="artist" :trackTitle="title"></track-icons>
      </div>
    </div>
  </div>
</template>

<script>
import trackIcons from './track-icons.vue';
export default {
  components: { trackIcons },
  name: "LastTrack",
  props: {
    artist: {
      type: String,
      default: "ARTIST",
    },
    title: {
      type: String,
      default: "TITLE",
    },
    album: {
      type: String,
      default: "ALBUM",
    },
    year: {
      type: String,
      default: null,
    },
    discogsUrl: {
      type: String,
      default: null,
    },
    spotifyUri: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hoverCover: false,
    };
  },
  methods: {
    shareViaWebShare() {
      navigator.share({
        title: 'Nuance Radio',
        text: `I discovered ${this.title} by ${this.artist} on Nuance Radio.`,
        url: 'https://nuance.radio'
      })
    }
  },
  computed: {
    coverBackground() {
      if (!this.hoverCover) {
        return `background-image: url('${this.imageUrl}';`;
      } else {
        return `background-image: linear-gradient(90deg, rgba(206, 206, 206, 0.8) 0.00%,rgba(206, 206, 206, 0.8) 0.00%,rgba(206, 206, 206, 0.8) 0.00%), url('${this.imageUrl}';`;
      }
    },
    webShareApiSupported() {
      return navigator.share
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}
.image-container {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  transition: 0.5s;
  background-size: cover;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 0.5s;
  margin-bottom: 5px;
}

.hover-text-artist {
  color: #000000;
  font-size: 1rem;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Syne;
  font-weight: 600;
  line-height: 1.2;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: 2px;
}
.hover-text-track {
  color: #000000;
  font-size: .9rem;
  align-self: center;
  text-align: center;
  font-family: Syne;
  line-height: 1.5;
  /* margin: 0 var(--dl-space-space-unit) var(--dl-space-space-halfunit)  */
}

.hover-background {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  position: relative;
  align-self: center;
  transition: 0.5s;
  padding-bottom: 100%;
  background-size: cover;
}
.hover-track {
  left: 0px;
  right: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  opacity: 0;
  position: absolute;
  transition: 0.5s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.hover-track:hover {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 1600px) {
  .hover-text-artist {
    font-size: 1vw;
  }
  .hover-text-track {
    font-size: 0.9vw;
  }
}
@media (max-width: 991px) {
  .hover-text-artist {
    color: #000000;
    font-size: 2vw;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    font-family: Syne;
    margin-bottom: 2px;
  }
  .hover-text-track {
    color: #000000;
    font-size: 1.8vw;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: 4px;
  }
  .image-container {
    width: 100%;
    align-self: center;
    padding-right: 0px;
  }

  .hover-background {
    width: 100%;
    align-self: center;
    padding-right: 0px;
  }

  .icons-pasttrack {
    width: 10vw;
    height: 5vw;
  }
}
@media (max-width: 767px) {
  .hover-text-artist {
    font-size: 2.7vw;
  }
  .icons-pasttrack {
    width: 10vw;
    height: 5vw;
  }
  .hover-text-track {
    font-size: 2.5vw;
  }
}
@media (max-width: 479px) {
  .hover-text-artist {
    font-size: 4vw;
  }
  .icons-pasttrack {
    width: 25vw;
    height: 4vw;
  }
  .hover-text-track {
    font-size: 3vw;
  }
}
</style>
