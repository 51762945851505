<template>
  <div class="about">
    <div class="danseuse">
      <img src="/playground_assets/danseuse.png" alt="Nuance Logo" />
    </div>
    <div class="about-text">

      Nuance defines itself as an intermediary state or subtle difference.<br />
      A sequence of adjacent intervals, either colors or sounds.<br />
      Nuance brings things together, and unites differences.<br />
      While today’s world tends to disunite, we wish to federate contrasts, more than ever.<br /><br />
      <span style="font-weight: 500">Nuance Radio is our sound library, available 24/7 on this website.</span><br /><br />
      We organize parties every other month in Paris, and a mini-festival every summer in the French countryside.
    </div>
    <div class="socials-container">
      <socials></socials>
    </div>
  </div>
</template>

<script>
import Socials from './socials.vue'
export default {
  components: { Socials },
  name: 'About'
}
</script>

<style scoped>
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.danseuse {
  width: 20%;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 60px;
}
.danseuse img {
  width: 100%;
  object-fit: cover;

}
.about-text {
    text-transform: none;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    font-style: normal;
    font-family: Syne;
    font-weight: 400;
    font-size: 1rem;
    text-align: justify;
    background-color: white;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-bottom: 90px;
    margin-right: 20px;
    margin-left: 20px;
}
.socials-container {
  margin-top: 40px;
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
}
@media(max-width: 991px) {
  .socials-container {
    display: none;
  }
}

</style>
